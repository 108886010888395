<template>
  <div class="official--partner-action-card">
    <Info
      :developer_image="developer_image"
      :developer_web="developer_web"
      :name="`${$t('officialPartner.developedBy')}: ${name}`"
    />
  </div>
</template>

<script>
const Info = () => import('@/components/official-partner/partials/info');
export default {
  props: {
    developer_image: {
      type: String,
    },
    developer_web: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  components: {
    Info,
  },
};
</script>

<style></style>
